<template>
    <router-view/>
    <vue-progress-bar />
</template>


<style>
@import '@/assets/css/style.css';
</style>

<script>
  // import '@/assets/js/app.min.js';
  import axios from 'axios'

  export default {
    data() {
      return {

      }
    },
    components: {

    },
    beforeCreate() {
      this.$store.commit('initializeStore')

      const token = this.$store.state.token

      if (token) {
        axios.defaults.headers.common['Authorization'] = "Token " + token
      } else {
        axios.defaults.headers.common['Authorization'] = ""
      }
    },
    mounted() {
      this.$Progress.finish();

      document.title = 'Главная'
    },
    created() {
      this.$Progress.start();

      this.$router.beforeEach((to, from, next) => {

        if (to.meta.progress !== undefined) {
          let meta = to.meta.progress;

          this.$Progress.parseMeta(meta);
        }

        this.$Progress.start();

        next();
      });

      this.$router.afterEach((to, from) => {
        this.$Progress.finish();
      });
    }, 
    methods: {

    },
    computed: {

    },
    watch: {

    },
  }
</script>